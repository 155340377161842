import {DatePicker, Divider, Slider} from "@nextui-org/react";
import {parseZonedDateTime, parseAbsoluteToLocal} from "@internationalized/date"

const Sliders = ({params, setSelectedParams, selectedParams}) => {
    const depths = params.depths
    const DateAvaliable = (date) => {
        const CalendarDate = `${date.year}-${("0" + date.month).slice(-2)}-${("0" + date.day).slice(-2)}`;
        const AvaliableDates = params.dates
        return !(AvaliableDates.indexOf(CalendarDate) > -1)
    }

    const handlePickDate = (v) => {
        setSelectedParams((prevState) => ({
            ...prevState,
            date: `${v.year}-${("0" + v.month).slice(-2)}-${("0" + v.day).slice(-2)}`
        }))
    }

    const DepthEnabled = () => {
        console.log(selectedParams.variable)
        switch (selectedParams.variable){
            default: return false;
            case "temp": return false;
            case "salt": return false;
            case "ocn": return false;
            case "ssh": return true;
            case "iconc": return true;
            case "ithick": return true;
            case "ice": return true;
            case "wnd": return true;
        }

    }

    return (
        <div>

            <div className="flex gap-3 flex-col h-full items-center justify-between w-full">
                <DatePicker
                    isDateUnavailable={DateAvaliable}
                    defaultValue={parseAbsoluteToLocal(new Date(params.plot.date).toISOString())}
                    color="foreground"
                    variant="bordered"
                    aria-label={'Date'}
                    granularity={'day'}
                    classNames={{
                        base: "w-full dark",
                        calendar: "dark"
                    }}
                    onChange={handlePickDate}
                    className="w-full dark"/>
                <div
                    className="min-h-full min-w-full flex flex-col gap-2 border-1 border-foreground-300 p-4 rounded-lg">
                    <Slider
                        label="Время"
                        size="md"
                        step={1}
                        maxValue={24}
                        minValue={0}
                        color="foreground"
                        orientation="horizontal"
                        onChange={(e) => {setSelectedParams((prevState) => ({...prevState, time: e}))}}
                        aria-label="Temperature"
                        defaultValue={parseInt(params.plot.time)}
                        getValue={(time) => `${time} ч.`}
                        className="min-w-14 transition"
                        classNames={{
                            track: "active:scale-105 transition"
                        }}
                        showSteps={true}

                    />
                    <Divider/>
                    <Slider
                        label="Глубина"
                        size="md"
                        step={1}
                        maxValue={4}
                        isDisabled={DepthEnabled()}
                        minValue={0}
                        color="foreground"
                        orientation="horizontal"
                        aria-label="Temperature"
                        defaultValue={params.plot.depth ? depths.indexOf(params.plot.depth) : 0}
                        onChange={(e) => {setSelectedParams((prevState) => ({...prevState, depth: depths[parseInt(e)]}))}}
                        getValue={(depth) => `${(depths[parseInt(depth)])} m`}
                        className={`min-w-14 transition ${DepthEnabled() ? "opacity-10" : ""}`}
                        classNames={{
                            track: !DepthEnabled() ? "active:scale-105 transition" : ""
                        }}
                        showSteps={true}
                    />
                </div>

            </div>
        </div>
    )
}

export default Sliders