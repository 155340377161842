import {FaTemperatureHalf} from "react-icons/fa6";
import {LiaWaterSolid} from "react-icons/lia";
import {GiIceberg, GiStrikingArrows} from "react-icons/gi";
import {SiIced} from "react-icons/si";
import {FaDiceD20, FaWind} from "react-icons/fa";

export const label = {
    temp: "Температура воды",
    ssh: "Уровень моря",
    salt: "Солёность воды",
    ithick: "Толщина льда",
    ice: "Скорость дрейфа льда",
    ocn: "Скорость течения",
    iconc: "Сплочённость льда",
    wnd: "Скорость ветра"
}

export const Icons = {
    temp: (<FaTemperatureHalf
        size={20}/>),
    ssh: (<LiaWaterSolid
        size={25}/>),
    salt: (<p
        className="text-icon text-xl">‰</p>),
    ithick: (<GiIceberg size={25} />),
    ice: (<SiIced size={20} />),
    ocn: (<GiStrikingArrows size={20}/>),
    iconc: (<FaDiceD20 size={20} />),
    wnd: (<FaWind size={20}/>)
}