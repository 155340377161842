import {Map} from "./components/Map";
import MapMenu from "./components/MapMenu";
import {useEffect, useState} from "react";
import axios from "axios";
import {Chip, Spinner} from "@nextui-org/react";
import {ValueBar} from "./components/ValueBar";
import {API_URL} from "./Config";



function App() {
    const [params, setParams] = useState(null);
    const [selectedPlot, setSelectedPlot] = useState(null);
    const [plotData, setPlotData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedParams, setSelectedParams] = useState();
    const fetchData = async () => {
        const result = await axios.get(`${API_URL}/params/`)
        if(result){
            setParams(result.data)
            setSelectedPlot(result.data.plot.file)
            setPlotData(result.data.plot)
            setSelectedParams({
                date: result.data.plot.date,
                time: result.data.plot.time,
                variable: result.data.plot.variable,
                depth: result.data.plot.depth,
            })
            setIsLoading(false)
        }
    }
    const fetchPlot = async (data) => {
        try {
            const result = await axios.post(`${API_URL}/plot/`, data)
            if (result) {
                setSelectedPlot(result.data.file)
                setPlotData(result.data)
            }
        }catch(error) {
            setError('График не найден')
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }
    useEffect(() => {
        if (!isLoading) {
            const delayDebounceFn = setTimeout(() => {
                fetchPlot(selectedParams);
            }, 1000);

            // Cleanup function to clear the timeout if selectedParams changes
            return () => clearTimeout(delayDebounceFn);
        }
    }, [selectedParams, isLoading]);
    useEffect(() => {
        fetchData()
    }, []);
    if(isLoading) return <div className="flex w-screen bg-foreground-100 h-screen justify-center items-center"><Spinner/></div>
    return (
        <div className="h-screen w-screen overflow-hidden relative">
            <ValueBar variable={plotData}/>
            <Map params={params} selectedPlot={selectedPlot}/>
            <MapMenu variable={plotData} params={params} setSelectedParams={setSelectedParams} selectedParams={selectedParams} error={error}/>
        </div>
    );
}

export default App;
