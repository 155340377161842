import {Button, Tooltip} from "@nextui-org/react";
import {useEffect, useState} from "react";
import {Icons, label} from "../utils/Parametres";

const ParameterPicker = ({params, setSelectedParams}) => {
    const [activeButton, setActiveButton] = useState(params.plot.variable);
    const [hoverVar, setHoverVar] = useState(null);
    useEffect(() => {
        setSelectedParams((prevState) => ({...prevState, variable: activeButton}));
    }, [activeButton]);
    const handleOpen = (variable, e) => {
        if(e){
            setHoverVar(variable)
        }
        else {
            setHoverVar(null)
        }
    }

    return (
        <div className="flex flex-col gap-2">
            <div className="flex flex-row justify-between p-2 bg-foreground-100 sm:bg-foreground-50 rounded-lg overflow-x-auto">
                {params.variables.map((variable, index) => (
                    <Tooltip key={variable} onOpenChange={(e) => handleOpen(variable, e)} className="hidden">
                        <div
                             className={`${activeButton === variable ? "bg-foreground-400" : ""} rounded-lg ease-in-out duration-500 transition p-1 hover:scale-110`}>
                            <Button
                                variant="light" isIconOnly
                                onClick={() => setActiveButton(variable)}>{Icons[variable]}</Button>
                        </div>
                    </Tooltip>
                ))}
            </div>
            <div className="flex items-center justify-center">
                <p className="transition bg-foreground-100 sm:bg-foreground-50 min-w-52 text-center px-2 pb-1 rounded-lg">{hoverVar ? label[hoverVar] : label[activeButton]}</p>
            </div>
        </div>
    )
}

export default ParameterPicker;