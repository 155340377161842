export const ParamsLabel = {
    temp: {
        label: "Температура воды",
        unit: '°C'
    },
    ssh: {
        label: "Уровень моря",
        unit: 'м.'
    },
    salt: {
        label: "Солёность воды",
        unit: 'psu'
    },
    ithick: {
        label: "Толщина льда",
        unit: 'м.'
    },
    ice: {
        label: "Скорость дрейфа льда",
        unit: 'м/с'
    },
    ocn: {
        label: "Скорость течения",
        unit: 'м/с'
    },
    iconc: {
        label: "Сплочённость льда",
        unit: ''
    },
    wnd: {
        label: "Скорость ветра",
        unit: 'м/с'
    }
}