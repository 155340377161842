import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {useEffect, useRef, useState} from "react";
import {MEDIA_URL} from "../Config";


export const Map = ({params, selectedPlot}) => {
    mapboxgl.accessToken = 'pk.eyJ1IjoicW9ud2VyaWsiLCJhIjoiY2xnMHhyOGRqMDFuYTNvbDJidDh3am9yMCJ9.aRCfCMd8RolsmrGA7U4dtA';
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(params.center_lon);
    const [lat, setLat] = useState(params.center_lat);
    const [zoom, setZoom] = useState(params.zoom);
    const coords = () => {
        if(selectedPlot.indexOf('ocn') > -1) {
            return [
                [
                    params.longitude_min - 0.5,
                    params.latitude_max + 0.12
                ],
                [
                    params.longitude_max + 0.25,
                    params.latitude_max + 0.08
                ],
                [
                    params.longitude_max + 0.1,
                    params.latitude_min - 0.1
                ],
                [
                    params.longitude_min - 0.1,
                    params.latitude_min - 0.07
                ]
            ]
        }return [
            [
                params.longitude_min,
                params.latitude_max
            ],
            [
                params.longitude_max,
                params.latitude_max
            ],
            [
                params.longitude_max,
                params.latitude_min
            ],
            [
                params.longitude_min,
                params.latitude_min
            ]
        ]
    }
    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            language: 'ru',
            style: 'mapbox://styles/qonwerik/cluqz4go6008p01ps4ggsdkyh',
            center: [lng, lat],
            zoom: zoom
        });
        if(selectedPlot) {
            map.current.on('style.load', () => {
                map.current.addSource('plot', {
                    type: "image",
                    url: MEDIA_URL + selectedPlot,
                    coordinates: coords()
                });
                map.current.addLayer({
                    id: 'layer',
                    type: 'raster',
                    source: 'plot',
                })
            })
        }
    }, [lat, lng, map, zoom]);

    useEffect(() => {
        if(map.current) {
            const source = map.current.getSource('plot');
            if(source) {
                source.updateImage({
                    url:MEDIA_URL + selectedPlot,
                    coordinates: coords()
                })
                map.current.removeLayer('layer')
                map.current.addLayer({
                    id: 'layer',
                    type: 'raster',
                    source: 'plot',
                })
            }
        }
    }, [selectedPlot]);
    return (
        <div className="w-screen h-screen overflow-hidden">
            <div ref={mapContainer} className="map-container h-full w-full"/>
        </div>
    )
}