import {
    Accordion,
    AccordionItem, Button,
    Card,
    CardBody, Modal, ModalBody, ModalContent, useDisclosure
} from "@nextui-org/react";
import {motion} from "framer-motion";
import ParameterPicker from "./ParameterPicker";
import Sliders from "./Sliders";
import {AiOutlineWarning} from "react-icons/ai";
import {ParamsLabel} from "../utils/ParamsLabel";
import {Icons, label} from "../utils/Parametres";
import {DateFormat} from "../utils/DateFormat";
import {NextCalendar} from "../icons/NextCalendar";
import {IoTimeOutline} from "react-icons/io5";
import {RxHeight} from "react-icons/rx";
import {useState} from "react";

const MapMenu = ({params, setSelectedParams, error, variable, selectedParams}) => {
    const {isOpen, onOpenChange, onOpen} = useDisclosure()
    const [openParams, setOpenParams] = useState(false);
    const step = (variable.max_value - variable.min_value) / 4
    return (
        <>
            <div
                className="fixed left-0 sm:left-5 bottom-9 animate-appearance-in hidden flex-col gap-4 sm:flex">
                <motion.div
                    initial={{y: 50, opacity: 0}}
                    animate={error ? {y: 0, opacity: 1} : {y: 50, opacity: 0}}
                    transition={{duration: 0.5, type: "spring", stiffness: 180, bounce: 50}}
                    className="w-full px-4"
                >
                    <div className="w-full bg-danger rounded-xl px-2 flex items-center text-white py-2">
                        <AiOutlineWarning size={20}/>
                        <p className="ml-4 mr-2">{error}</p>
                    </div>
                </motion.div>
                <div className="flex gap-4 items-center">
                    <Accordion variant="splitted" className="dark text-white" onSelectionChange={() => setOpenParams(!openParams)}>
                        <AccordionItem title="Параметры"
                                       className="dark text-white max-w-dvw sm:max-w-full backdrop-blur-[15px] bg-white/5 pr-2">
                            <div className="max-w-screen sm:max-w-full">
                                <Card className="bg-transparent shadow-none">
                                    <CardBody>
                                        <div className="flex flex-col gap-3">
                                            <Sliders setSelectedParams={setSelectedParams} params={params}
                                                     selectedParams={selectedParams}/>
                                            <ParameterPicker setSelectedParams={setSelectedParams} params={params}/>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </AccordionItem>
                    </Accordion>
                    <motion.div
                        animate={openParams ? {opacity: 0, display: "none"} : {display: "flex"}}
                        transition={openParams ? {delay: 0, duration: 0} : {delay: 0.4, duration: 0.2}}

                        className="flex flex-col gap-2 z-50 text-white w-full bg-white/5 rounded-xl p-1 items-center">
                        <div className="flex items-center w-full">
                            <div className="opacity-50">{Icons[selectedParams.variable]}</div>
                            <p className="w-full">{label[selectedParams.variable]}</p>
                        </div>
                        <div className="flex gap-2">
                            <div className="flex items-center gap-2">
                                <div className="flex gap-1 items-center">
                                    <NextCalendar/>
                                    <p>{DateFormat(selectedParams.date)} </p>
                                </div>
                                <div className="flex items-center">
                                    <IoTimeOutline size={20} className="opacity-50"/>
                                    <p> {selectedParams.time}:00</p>
                                </div>

                            </div>
                            <div className="flex items-center">
                                <RxHeight className="opacity-50" size={20}/>
                                <p>{selectedParams.depth}</p>
                                <p className="pl-1">м.</p>
                            </div>
                        </div>

                    </motion.div>
                </div>
            </div>
            <div className="absolute top-2 flex items-center sm:hidden w-dvw px-4">
                <div className="self-center flex flex-col gap-1 z-50 w-full">
                    <p className="w-full text-sm items-center text-white text-center">{ParamsLabel[variable.variable].label} ({ParamsLabel[variable.variable].unit})</p>
                    <div
                        className={`h-3 w-full rounded-xl flex bg-gradient-to-r from-jet-0 via-jet-4 to-jet-8`}></div>
                    <div className="flex flex-row-reverse text-white justify-between w-full z-50 text-sm">
                        <p>{variable.max_value.toString().slice(0, 3)}</p>
                        <p>{(variable.min_value + (step * 3)).toString().slice(0, 3)}</p>
                        <p>{(variable.min_value + (step * 2)).toString().slice(0, 3)}</p>
                        <p>{(variable.min_value + step).toString().slice(0, 3)}</p>
                        <p>{variable.min_value.toString().slice(0, 3)}</p>
                    </div>
                </div>
            </div>
            <div className="flex gap-4 fixed left-2 bottom-5 sm:hidden items-center mr-2">
                <Button onClick={onOpenChange} className="dark">Параметры</Button>
                <div
                    className="flex z-50 text-white w-full bg-white/5 rounded-xl p-1 items-center">
                    <div className="flex items-center w-full">
                        <div className="opacity-50">{Icons[selectedParams.variable]}</div>
                        <p className="w-full">{label[selectedParams.variable]}</p>
                    </div>
                    <div className="flex gap-2 w-full">
                        <div className="flex items-center gap-2">
                            <div className="flex gap-1 items-center">
                                <NextCalendar/>
                                <p>{DateFormat(selectedParams.date)} </p>
                            </div>
                            <div className="flex items-center w-full">
                                <IoTimeOutline size={20} className="opacity-50"/>
                                <p className="pl-1"> {selectedParams.time}:00</p>
                            </div>

                        </div>
                        <div className="flex items-center w-full">
                            <RxHeight className="opacity-50" size={20}/>
                            <p>{selectedParams.depth}</p>
                            <p className="pl-1">м.</p>
                        </div>
                    </div>

                </div>
            </div>
            <Modal className="sm:hidden dark" isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    <ModalBody>
                        <div className="max-w-screen sm:max-w-full">
                            <motion.div
                                initial={{y: 50, opacity: 0}}
                                animate={error ? {y: 0, opacity: 1} : {y: 50, opacity: 0}}
                                transition={{duration: 0.5, type: "spring", stiffness: 240, bounce: -5}}
                                className="w-full px-4"
                            >
                                <div className="w-full bg-danger rounded-xl px-2 flex items-center text-white py-2">
                                    <AiOutlineWarning size={20}/>
                                    <p className="ml-4 mr-2">{error}</p>
                                </div>
                            </motion.div>
                            <Card className="bg-transparent shadow-none">
                                <CardBody>
                                    <div className="flex flex-col gap-3">
                                        <Sliders setSelectedParams={setSelectedParams} params={params}
                                                 selectedParams={selectedParams}/>
                                        <ParameterPicker setSelectedParams={setSelectedParams} params={params}/>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default MapMenu
