import {ParamsLabel} from "../utils/ParamsLabel";

export const ValueBar = ({variable}) => {
    const step = (variable.max_value - variable.min_value) / 4
    return (
        <>
            <div
                className="absolute right-5 bottom-0 z-20 text-white h-full hidden flex-col gap-6 items-center justify-center sm:flex">
                <p>{ParamsLabel[variable.variable].label} ({ParamsLabel[variable.variable].unit})</p>
                <div className="flex flex-row gap-4 items-center h-fit">
                    <div className={`h-96 w-6 rounded-xl flex bg-gradient-to-t from-jet-0 via-jet-4 to-jet-8`}></div>
                    <div className="flex flex-col gap-14 h-96 justify-between">
                        <p>{variable.max_value.toString().slice(0, 5)}</p>
                        <p>{(variable.min_value + (step * 3)).toString().slice(0, 5)}</p>
                        <p>{(variable.min_value + (step * 2)).toString().slice(0, 5)}</p>
                        <p>{(variable.min_value + step).toString().slice(0, 5)}</p>
                        <p>{variable.min_value.toString().slice(0, 5)}</p>
                    </div>
                </div>
            </div>
        </>
    )
}